<template>
    <!-- 关于威有 -->
    <div class="about">
        <!-- 荣誉资质 -->
        <Honor></Honor>
        <!-- 发展历程 -->
        <Adevelopment></Adevelopment>
        <!-- 人才招聘 -->
        <Recruit></Recruit>
    </div>
</template>

<script>
import Honor from '../components/content/Abouts/Honor/Honor.vue';
import Adevelopment from '../components/content/Abouts/Adevelopment/Adevelopment.vue';
import Recruit from '../components/content/Abouts/Recruit/Recruit.vue';

export default {
    name: 'About',
    components: {
        Honor,
        Adevelopment,
        Recruit,
    },
};
</script>

<style lang="less" scoped>
.about {
    margin: 0 auto;
}
</style>
